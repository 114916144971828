
<template>
  <v-chip :color="acceptedAt ? 'success' : 'error'">
    <div
      v-if="acceptedAt"
      class="d-flex gap"
    >
      <span>{{$t('t.CGUAccepted')}}</span>
      <span v-if="text">
        {{text}}
      </span>
      <calendar-date
        :date="acceptedAt"
        :lowerCase="true"
      />
    </div>
    <span v-else>
      {{$t('t.CGUAcceptancePending')}}
    </span>
  </v-chip>
</template>

<script>
export default {
  components: {
    CalendarDate: () => import('@/components/calendar-date')
  },
  props: {
    acceptedAt: String,
    text: String
  }
}
</script>
